import { ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT } from "../common/routes";
import { getApplicationURL } from "../common/utilityFunctions";
export var AuthEndpoint;
(function (AuthEndpoint) {
    AuthEndpoint["SIGN_IN"] = "signIn";
    AuthEndpoint["SIGN_OUT"] = "signOut";
    AuthEndpoint["REFRESH_TOKEN"] = "refreshToken";
})(AuthEndpoint || (AuthEndpoint = {}));
export const BETA_EMAIL_DOMAINS_ALLOWLIST = [
    "amazon.com"
];
export const addParamAndEndpointToBuildFullAuthURL = (endpoint, gandalfProps) => {
    const applicationURL = getApplicationURL();
    const urlWithPath = new URL(`${applicationURL}/${endpoint}`);
    urlWithPath.searchParams.append("redirect_uri", `${applicationURL}${ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT}`);
    if (gandalfProps) {
        urlWithPath.searchParams.append("identity_provider", gandalfProps.identity_provider);
        urlWithPath.searchParams.append("require_email_verification", gandalfProps.require_email_verification);
    }
    return urlWithPath.toString();
};
export const getSignInURL = (gandalfProps) => {
    return addParamAndEndpointToBuildFullAuthURL(AuthEndpoint.SIGN_IN, gandalfProps);
};
export const signOut = async () => {
    const signOutURL = addParamAndEndpointToBuildFullAuthURL(AuthEndpoint.SIGN_OUT);
    const signOutResponse = await fetch(signOutURL, {
        credentials: "include",
    });
    return signOutResponse;
};
export const refreshToken = async () => {
    try {
        const refreshTokenURL = addParamAndEndpointToBuildFullAuthURL(AuthEndpoint.REFRESH_TOKEN);
        const refreshTokenResponse = await fetch(refreshTokenURL, { method: "POST" });
        return refreshTokenResponse;
    }
    catch (err) {
        throw Error("Error refreshing token");
    }
};
export const validateEmailDomainAllowlisted = (email) => {
    const emailParts = email.split("@");
    if (emailParts.length != 2) {
        throw new Error("Invalid email!");
    }
    return BETA_EMAIL_DOMAINS_ALLOWLIST.includes(emailParts[1]);
};
