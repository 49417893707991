import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
/**
 * Formats currency so it will return a decimal only if there is a cent value
 * @param amount dollar value
 * @returns a string with the currency value, will return 0 if input is null
 */
export const formatCurrency = (amount) => {
    const returnAmount = amount ?? 0;
    if (returnAmount % 1 === 0) {
        return returnAmount.toString();
    }
    else {
        return returnAmount.toFixed(2);
    }
};
export const getDiscount = (price, discountList) => {
    // Sort the discount list by priceThreshold in ascending order
    const sortedDiscounts = [...discountList].sort((a, b) => a.priceThreshold - b.priceThreshold);
    // Find the applicable discount tier
    for (let i = 0; i < sortedDiscounts.length; i++) {
        const currentTier = sortedDiscounts[i];
        const nextTier = sortedDiscounts[i + 1];
        if (price >= currentTier.priceThreshold) {
            // If this is the last tier or price is less than next tier threshold
            if (!nextTier || price < nextTier.priceThreshold) {
                return (price * currentTier.percentageDiscount) / 100;
            }
        }
    }
    // Return 0 if price is below the minimum threshold
    return 0;
};
const AWS_ACCOUNT_TYPE_PARTNER = 'partner';
export const SubscriptionSummaryContainer = (props) => {
    const { t } = useTranslation();
    const pricePerSeat = props?.offerPriceData?.getPayerAccountOfferPrice?.pricePerSeat || 500;
    const discountList = props?.offerPriceData?.getPayerAccountOfferPrice?.discountList || [];
    const tax = 0;
    const annualTotal = (props.numberOfSeats || 0) * pricePerSeat;
    const discount = getDiscount(annualTotal, discountList);
    const orderTotal = annualTotal + tax - discount;
    return (_jsx(Container, { header: _jsx(Box, { variant: "h2", children: t("subscription_form_summary_header") }), children: _jsxs(SpaceBetween, { size: "m", children: [_jsxs(SpaceBetween, { size: "xxxs", children: [_jsxs(ColumnLayout, { columns: 2, children: [_jsxs(Box, { children: [t("subscription_form_price_per_seat_label"), ":"] }), _jsxs(Box, { float: "right", children: ["$", formatCurrency(pricePerSeat)] })] }), _jsxs(ColumnLayout, { columns: 2, children: [_jsxs(Box, { children: [t("subscription_form_number_of_seats_label"), ":"] }), _jsx(Box, { float: "right", children: props.numberOfSeats ? props.numberOfSeats : '-' })] }), _jsxs(ColumnLayout, { columns: 2, children: [_jsxs(Box, { children: [t("subscription_form_discount_label"), ":"] }), _jsx(Box, { float: "right", children: discount ? discount : '-' })] }), _jsxs(ColumnLayout, { columns: 2, children: [_jsxs(Box, { children: [t("subscription_form_annual_total_label"), ":"] }), _jsx(Box, { float: "right", children: annualTotal ? '$' + formatCurrency(annualTotal) : '-' })] })] }), _jsxs(ColumnLayout, { columns: 2, children: [_jsxs(Box, { variant: "h3", children: [t("subscription_form_order_total_label"), ":"] }), _jsx(Box, { variant: "h3", float: "right", children: orderTotal ? '$' + formatCurrency(orderTotal) : '-' })] }), _jsx(Alert, { statusIconAriaLabel: "Info", children: t("subscription_form_summary_info", { awsAccountId: props.awsAccountId }) }), _jsx(Box, { variant: "small", children: props.awsAccountType && props.awsAccountType === AWS_ACCOUNT_TYPE_PARTNER
                        ? t("subscription_form_terms_and_conditions_partner_customer_text")
                        : t("subscription_form_terms_and_conditions_regular_customer_text") })] }) }));
};
