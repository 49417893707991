import { gql, useMutation } from "@apollo/client";
export const CREATE_PAYMENT_METHOD = gql `
  mutation CreatePaymentMethod($input: CreateAWSAccountPaymentMethodInput!) {
    paymentMethod: createAWSAccountPaymentMethod(input: $input) {
      id
    }
  }
`;
export const ADD_PAYMENT_METHOD_TO_CHECKOUT_SESSION = gql `
  mutation addPaymentMethodToCheckoutSession(
    $input: AddPaymentMethodToCheckoutSessionInput!
  ) {
    checkoutSession: addPaymentMethodToCheckoutSession(input: $input) {
      id
    }
  }
`;
export const FINALIZE_SESSION = gql `
  mutation FinalizeCheckoutSession($input: FinalizeCheckoutSessionInput!) {
    checkoutSession: finalizeCheckoutSession(input: $input) {
      id
    }
  }
`;
export const FINALIZE_TEAM_SUBSCRIPTION = gql `
  mutation FinalizeCheckoutSession($input: FinalizeTeamSubscriptionInput!) {
    finalizeTeamSubscription(
      input: $input
    )
  }
`;
export const useFinalizeTeamSubscriptionMutation = (callback, errorCallback) => {
    const [finalizeTeamSubscription, { data, loading: finalizeTeamSubscriptionLoading, error: finalizeTeamSubscriptionError }] = useMutation(FINALIZE_TEAM_SUBSCRIPTION);
    return { finalizeTeamSubscription, finalizeTeamSubscriptionLoading, finalizeTeamSubscriptionError };
};
export const useCreateTeamSubscriptionMutation = (awsAccountId, callback, errorCallback) => {
    const [createTeamSubscription, { data, loading: createTeamSubscriptionLoading, error: createTeamSubscriptionError }] = useMutation(CREATE_TEAM_SUBSCRIPTION);
    return { createTeamSubscription, createTeamSubscriptionLoading, createTeamSubscriptionError };
};
export const CREATE_SB_DIGITAL_CHECKOUT_SESSION = gql `
  mutation createNewSbDigitalOrderCheckoutSession(
    $input: CreateNewSbDigitalOrderCheckoutSessionInput!
  ) {
    createNewSbDigitalOrderCheckoutSession(input: $input)
  }
`;
export const CREATE_TEAM_SUBSCRIPTION_BAK = gql `
  mutation createTeamSubscription(
    $input: linkedAccountId!
  ) {
    createTeamSubscription(linkedAccountId: $input)
  }
`;
export const CREATE_TEAM_SUBSCRIPTION = gql `
  mutation createTeamSubscription {
    createTeamSubscription(input: { linkedAccountId: "111111111111" })
  }
`;
