import { getNodeEnvironment, NodeEnvironment } from "../common/nodeEnvironment";
// Add the values from your personal Isengard account after deploying AwsTcCheckoutFrontendCDK
export const DEV_ALIAS = "";
export const DEV_RUM_GUEST_ROLE_ARN = "";
export const DEV_RUM_IDENTITY_POOL_ID = "";
export const DEV_RUM_APP_ID = "";
// Constant RUM values for all stages
export const RUM_APP_VERSION = "1.0.0";
export const RUM_APP_REGION = "us-east-1";
export const RUM_APP_ENDPOINT = "https://dataplane.rum.us-east-1.amazonaws.com";
export const RUM_APP_TELEMETRIES = ["errors", "http", "performance"];
export const DEV_RUM = {
    appId: DEV_RUM_APP_ID,
    rumStageConfig: {
        guestRoleArn: DEV_RUM_GUEST_ROLE_ARN,
        identityPoolId: DEV_RUM_IDENTITY_POOL_ID,
        sessionSampleRate: 0.5,
    },
};
export const BETA_RUM = {
    appId: "934c9dab-31a6-4294-ab6d-2e572a443cf3",
    rumStageConfig: {
        guestRoleArn: "arn:aws:iam::160885264515:role/SBTSCheckoutRUMGuestRole",
        identityPoolId: "us-east-1:bae1c9e9-713d-4e5e-a819-7242262c4cc8",
        sessionSampleRate: 0.5,
    },
};
export const GAMMA_RUM = {
    appId: "41686b58-00f3-4f49-908d-392f534f9629",
    rumStageConfig: {
        guestRoleArn: "arn:aws:iam::615299763234:role/SBTSCheckoutRUMGuestRole",
        identityPoolId: "us-east-1:09532667-722b-49fc-97f7-66d2144c182a",
        sessionSampleRate: 0.5,
    },
};
// TODO - Update values after PROD stage deployment
export const PROD_RUM = {
    appId: "",
    rumStageConfig: {
        guestRoleArn: "",
        identityPoolId: "",
        sessionSampleRate: 0.5,
    },
};
export function getCurrentRumStage() {
    const env = getNodeEnvironment();
    switch (env) {
        case NodeEnvironment.BETA:
            return BETA_RUM;
        case NodeEnvironment.GAMMA:
            return GAMMA_RUM;
        case NodeEnvironment.PROD:
            return PROD_RUM;
        default:
            return DEV_RUM;
    }
}
