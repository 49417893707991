import { jsx as _jsx } from "react/jsx-runtime";
import "@amzn/awsui-global-styles/polaris.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { client } from "./common/api/client";
import { GET_CURRENT_USER } from "./user/api/getCurrentUser";
import { GET_SPMS_ID_VALIDATION, GET_SUBSCRIPTION_REQUEST_BY_AWS_ACCOUNT_ID } from "./user/api/queries";
const mocks = [
    {
        request: {
            query: GET_CURRENT_USER,
            // variables: { name: 'Buck' },
        },
        result: {
            "data": {
                "currentUser": {
                    "id": "65b84764-2df1-4116-a80b-0b0fa3b5c7df",
                    "emailAddress": "dummy-nmnydv+otp@amazon.com",
                    "firstName": "dummer",
                    "lastName": "",
                    "status": "ACTIVE",
                    "userRole": "LEARNER",
                    "auditMetadata": {
                        "createdAt": "2024-10-17T15:56:13.467Z",
                        "__typename": "AuditMetadata"
                    },
                    "gandalfDetails": {
                        "audiencePath": "OTP",
                        "__typename": "GandalfDetails"
                    },
                    "__typename": "User"
                }
            }
        },
    },
    {
        request: {
            query: GET_SUBSCRIPTION_REQUEST_BY_AWS_ACCOUNT_ID,
            variables: { awsAccountId: '11111111' },
        },
        result: {
            "data": {
                "subscriptionRequestByAWSAccountId": [
                    {
                        "firstName": "John",
                        "lastName": "Doe",
                        "businessEmail": "john@doe.com",
                        "companyName": "Amazon",
                        "endCustomerCompanyName": "Amazon",
                        "endCustomerCountry": "US",
                        "numberOfSeats": "40",
                        "signInMethod": "BUILDER_ID",
                        "organizationIdentityProvider": "AWS",
                        "preferredStartDate": "2024/12/12",
                        "laFirstName": "Jane",
                        "laLastName": "Doe",
                        "laEmail": "jane@doe.com",
                        "id": 0,
                        "submitted_by": "John Doe",
                        "order_date": "2024-11-07",
                        "order_number": 1000,
                        "subscription_status": "Request started",
                        "start_date": "2024/12/12",
                        "end_date": 1765497600000,
                        "seats": "40"
                    }
                ]
            }
        },
    },
    {
        request: {
            query: GET_SPMS_ID_VALIDATION,
            // variables: { awsAccountId: '11111111' },
        },
        result: {
            "data": {
                "spmsIdValidation": {
                    "valid": false
                }
            }
        },
    },
];
const root = createRoot(document.getElementById("root"));
root.render(_jsx(BrowserRouter, { children: _jsx(ApolloProvider, { client: client, children: _jsx(App, {}) }) }));
