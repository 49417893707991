import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
export const BillingDetailsFormContainer = ({ formData, onFormDataChange, errors }) => {
    const { t } = useTranslation();
    return (_jsx(Container, { header: _jsx(Header, { variant: "h2", children: t("billing_details_header") }), "data-testid": 'billing-details-form-container', children: _jsxs(SpaceBetween, { direction: "vertical", size: "l", children: [_jsx(FormField, { label: t("subscription_form_first_name_label"), stretch: false, errorText: errors.firstName, children: _jsx(Input, { value: formData.firstName, "data-testid": 'firstname', onChange: (e) => onFormDataChange('firstName', e), name: "firstName", placeholder: t("subscription_form_first_name_label") }) }), _jsx(FormField, { label: t("subscription_form_last_name_label"), errorText: errors.lastName, children: _jsx(Input, { value: formData.lastName, onChange: (e) => onFormDataChange('lastName', e), name: "lastName", placeholder: t("subscription_form_last_name_label") }) }), _jsx(FormField, { label: t("subscription_form_business_email_label"), errorText: errors.businessEmail, children: _jsx(Input, { value: formData.businessEmail, onChange: (e) => onFormDataChange('businessEmail', e), name: "businessEmail", placeholder: t("subscription_form_business_email_label") }) }), _jsx(FormField, { label: t("subscription_form_company_name_label"), errorText: errors.companyName, children: _jsx(Input, { value: formData.companyName, onChange: (e) => onFormDataChange('companyName', e), name: "companyName", placeholder: t("subscription_form_company_name_label") }) })] }) }));
};
