import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AWS_SIGN_IN_LITERAL, SEARCH_PARAM_CODE, SEARCH_PARAM_STATE, fetchAwsAccountJwt, fetchAwsAccountType, isUserRoot, } from "../auth/awsSignIn";
import { jwtDecode } from "jwt-decode";
import { ROUTE_SKILLBUILDER_SUBSCRIPTION_REQUEST } from "../common/routes";
export function getTomorrowsDate() {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
}
// This hook will run after user successfully authenticated through AWS Sign In and will contain a code in query pararms
// Fetch JWT using the code
// Set awsAccountJwt in state, triggering another useEffect
// The second useEffect will decode JWT and set AWS account id and JWT in session memory, triggering a third useEffect
// The third useEffect will fetch AWS account type (partner/regular) by sending the account id to SBTS backend
// then set account type in session and navigate to /subscription/configure
export const useHandleAwsSignIn = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [awsAccountJwt, setAWSAccountJwt] = useState();
    const [awsAccountId, setAwsAccountId] = useState();
    const [awsAccountType, setAwsAccountType] = useState();
    const paramsParsed = new URLSearchParams(location.search);
    const searchParamCode = paramsParsed.get(SEARCH_PARAM_CODE);
    const searchParamAuthState = paramsParsed.get(SEARCH_PARAM_STATE);
    const authState = useMemo(() => {
        if (searchParamAuthState) {
            const parsedState = JSON.parse(searchParamAuthState);
            if (parsedState.signInType === AWS_SIGN_IN_LITERAL) {
                return parsedState;
            }
        }
        return undefined;
    }, [searchParamAuthState]);
    // When we have a code & auth state from AWS Sign in, fetch AWS JWT
    useEffect(() => {
        // only start fetchAwsAccountJwt if auth is done (whether in E2E tests w/ temp creds
        // or using standard amplify auth)
        // Otherwise, the fetch call may be canceled during redirect for amplify/gandalf auth
        // and so the code will be deleted in the IDP
        if (searchParamCode) {
            try {
                fetchAwsAccountJwt(searchParamCode).then((idToken) => setAWSAccountJwt(idToken));
            }
            catch (e) {
            }
        }
    }, [searchParamCode]);
    // When we get a JWT, validate it and save account ID
    useEffect(() => {
        if (awsAccountJwt) {
            const payload = jwtDecode(awsAccountJwt);
            if (payload) {
                if (isUserRoot(payload)) {
                    sessionStorage.setItem("AwsAccountId", payload.name);
                    sessionStorage.setItem("AwsAccountJwt", awsAccountJwt);
                    setAwsAccountId(payload.name);
                }
                else {
                    console.log("No root user");
                }
            }
        }
    }, [awsAccountJwt]);
    useEffect(() => {
        if (awsAccountId) {
            fetchAwsAccountType(awsAccountId)
                .then((type) => {
                setAwsAccountType(type);
                sessionStorage.setItem("AwsAccountType", type);
                navigate(ROUTE_SKILLBUILDER_SUBSCRIPTION_REQUEST);
            });
        }
    }, [awsAccountId]);
};
