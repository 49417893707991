import { jsx as _jsx } from "react/jsx-runtime";
import { SubscriptionManagementPageLayout } from "../pages/SubscriptionManagementPage/SubscriptionManagementPage";
import { ConfigureSubscriptionPageLayout } from "../pages/ConfigureSubscriptionPage/ConfigureSubscriptionPage";
import HandleAwsSignIn from "../pages/HandleAwsSignIn/HandleAwsSignIn";
import { Navigate } from "react-router-dom";
export const ROUTE_ACCESS_DENIED = "/accessdenied";
export const ROUTE_HANDLE_AWS_SIGN_IN = "/aws-signin";
export const ROUTE_PAGE_NOT_FOUND = "*";
export const ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT = "/skillbuilderteam";
export const ROUTE_SKILLBUILDER_SUBSCRIPTION_REQUEST = `${ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT}/request`;
export const ROUTE_HOME = "/";
export const getSBTSCheckoutRoutes = () => {
    return [
        {
            path: ROUTE_HOME,
            element: _jsx(Navigate, { to: ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT }),
        },
        {
            path: ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT,
            element: _jsx(SubscriptionManagementPageLayout, {}),
        },
        {
            path: ROUTE_SKILLBUILDER_SUBSCRIPTION_REQUEST,
            element: _jsx(ConfigureSubscriptionPageLayout, {}),
        },
        {
            path: ROUTE_HANDLE_AWS_SIGN_IN,
            element: _jsx(HandleAwsSignIn, {})
        },
    ];
};
