import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Header, SpaceBetween, AppLayout } from '@amzn/awsui-components-react';
import "./AccessDenied.scss";
export const AccessDenied = () => {
    // const { authErrorMessageId } = useAuth();
    const content = () => {
        return (_jsx(Box, { className: "error-center", children: _jsxs(SpaceBetween, { size: "m", children: [_jsxs(Box, { children: [_jsx("img", { src: "/public/error-page.svg", alt: "error" }), _jsx(Header, { variant: "h1", children: " Access Denied " }), _jsx("p", { children: "You do not have access to this page." })] }), _jsx("br", {})] }) }));
    };
    return _jsx(AppLayout, { content: content(), toolsHide: true, navigationHide: true });
};
