import { useEffect, useState } from "react";
import { ApplicationUser } from "../auth/ApplicationUser";
import { getSignInURL, refreshToken, signOut, validateEmailDomainAllowlisted } from "../auth/authHelpers";
import { EMAIL_VERIFICATION_ERROR } from "../common/constants";
import { UserStatus } from "../user/types";
import { useGetCurrentUser } from "../user/api/getCurrentUser";
export const useAuth = () => {
    const [signedInUserEmail, setSignedInUserEmail] = useState(undefined);
    const [signedInUserId, setSignedInUserId] = useState(undefined);
    const [isLoggedIn, setIsLoggedIn] = useState(undefined);
    const [userRole, setUserRole] = useState(undefined);
    const [authErrorMessageId, setAuthErrorMessageId] = useState(undefined);
    const { data, error, loading } = useGetCurrentUser();
    useEffect(() => {
        if (error) {
            setIsLoggedIn(false);
            if (error.networkError?.statusCode === 413) {
                setAuthErrorMessageId("errorPage.accessDenied.413Error");
            }
            else if (error.networkError?.message.includes(EMAIL_VERIFICATION_ERROR) ||
                error.message?.includes(EMAIL_VERIFICATION_ERROR)) {
                const message = error.networkError?.message.includes(EMAIL_VERIFICATION_ERROR)
                    ? error.networkError.message
                    : error.message;
                const parsedString = message.substring(message.indexOf(".") + 1, message.length);
                const parsedJson = JSON.parse(parsedString);
                window.location.href = getSignInURL({
                    identity_provider: parsedJson.audiencePath,
                    require_email_verification: "true",
                });
            }
            else {
                window.location.href = getSignInURL();
            }
        }
        if (data) {
            const currentUser = data.currentUser;
            if (currentUser.status !== UserStatus.ACTIVE) {
                setIsLoggedIn(false);
                // SignOut is called to revoke tokens/clear HttpOnly cookies - as we want the user to be able to try again w/ different credentials
                signOut().then(() => setAuthErrorMessageId("errorPage.accessDenied.invalidRoleOrInactiveUser"));
            }
            else if (!validateEmailDomainAllowlisted(currentUser.emailAddress)) {
                setIsLoggedIn(false);
                signOut().then(() => setAuthErrorMessageId("errorPage.accessDenied.413Errorr"));
            }
            else {
                refreshToken()
                    .then(() => {
                    setSignedInUserEmail(currentUser.emailAddress);
                    setSignedInUserId(currentUser.id);
                    setIsLoggedIn(true);
                    setUserRole(currentUser.userRole);
                })
                    .catch(() => {
                    setIsLoggedIn(false);
                    signOut().then(() => (window.location.href = getSignInURL()));
                });
            }
        }
    }, [data, loading, error]);
    const potentialUser = new ApplicationUser({
        email: signedInUserEmail,
        isLoggedIn: isLoggedIn,
        role: userRole,
        id: signedInUserId,
    });
    return {
        user: potentialUser,
        isAuthErrorFound: !!authErrorMessageId,
        authErrorMessageId,
    };
};
