import { RoleType } from "../user/types";
import { NodeEnvironment, getNodeEnvironment } from "./nodeEnvironment";
export const FRONTEND_SELECTORS = {
    APP: "#app",
    HEADER: "#h",
    FOOTER: ".footer-wrapper",
};
//delay in milleseconds for debouncing
export const DEBOUNCE_DELAY = 800;
export var APPLICATION_URL;
(function (APPLICATION_URL) {
    APPLICATION_URL["TEST"] = "http://localhost:3000";
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    APPLICATION_URL["DEVELOPMENT"] = "http://localhost:3000";
    APPLICATION_URL["BETA"] = "https://beta.checkout.aws.training";
    APPLICATION_URL["GAMMA"] = "https://gamma.checkout.aws.training";
    APPLICATION_URL["PROD"] = "https://checkout.aws.training";
})(APPLICATION_URL || (APPLICATION_URL = {}));
export const ALLOWED_ROLES = [
    RoleType.SUPER_USER,
    RoleType.INTERNAL_EMPLOYEE,
    RoleType.SERVICE,
    RoleType.POST_AUTH_PRE_VIAS,
];
export const ALLOWED_ROLES_BULK_OPERATION = [
    RoleType.SUPER_USER,
    RoleType.INTERNAL_EMPLOYEE,
    RoleType.LEARNING_ADMIN,
    RoleType.SERVICE,
    RoleType.POST_AUTH_PRE_VIAS,
    RoleType.LEARNING_ACCOUNT_IT_CONTACT,
];
export const EMAIL_VERIFICATION_ERROR = "EmailAddress is not verified, verification required.";
const endpoints = {
    [NodeEnvironment.TEST]: `${APPLICATION_URL.TEST}/graphql`,
    [NodeEnvironment.DEVELOPMENT]: `${APPLICATION_URL.DEVELOPMENT}/graphql`,
    [NodeEnvironment.BETA]: `${APPLICATION_URL.BETA}/graphql`,
    [NodeEnvironment.GAMMA]: `${APPLICATION_URL.GAMMA}/graphql`,
    [NodeEnvironment.PROD]: `${APPLICATION_URL.PROD}/graphql`,
};
export const getClientEndpoint = () => {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        return endpoints[NodeEnvironment.TEST];
    }
    return endpoints[getNodeEnvironment()] || endpoints[NodeEnvironment.TEST];
};
