import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { getClientEndpoint } from "../constants";
const httpLink = createHttpLink({
    uri: getClientEndpoint(),
    credentials: "include",
    preserveHeaderCase: true,
    fetch: fetch,
});
export const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    connectToDevTools: true
});
