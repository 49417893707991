import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Header } from "./components";
import { createContext, useState } from "react";
import { Footer } from "./components/footer/Footer";
import { Route, Routes } from "react-router-dom";
import { getSBTSCheckoutRoutes } from "./common/routes";
import { AccessDenied } from "./pages/AccessDenied/AccessDenied";
import { useAuth } from "./hooks/useAuth";
import LoadingContent from "./components/LoadingContent/LoadingContent";
import { I18nextProvider } from "react-i18next";
import i18n from '../i18n/config';
import CookieConsentManager from "./cookieManagement/CookieConsentManager";
export const UserContext = createContext(undefined);
export const NotificationContext = createContext({ notification: undefined, setNotification: undefined });
export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState('');
    return (_jsx(NotificationContext.Provider, { value: { notification, setNotification }, children: children }));
};
function App() {
    const { user, isAuthErrorFound } = useAuth();
    const cookieConsentManager = new CookieConsentManager();
    cookieConsentManager.checkForCookieConsent();
    // If user email not found and no auth error then show the loading page
    if (!user.email && !isAuthErrorFound) {
        return _jsx(LoadingContent, { text: "Loading" });
    }
    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    };
    return (_jsx(I18nextProvider, { i18n: i18n, children: _jsx(UserContext.Provider, { value: user, children: _jsxs(NotificationProvider, { children: [_jsx(Header, { onLanguageChange: handleLanguageChange }), isAuthErrorFound ? (_jsx(AccessDenied, {})) : (_jsxs(Routes, { children: [...getSBTSCheckoutRoutes().map((route) => (_jsx(Route, { path: route.path, element: route.element }, route.path)))] })), _jsx(Footer, {})] }) }) }));
}
;
export default App;
