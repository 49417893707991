import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Box from "@amzn/awsui-components-react/polaris/box";
import styled from "styled-components";
import ContentLayout from "@amzn/awsui-components-react/polaris/content-layout";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
// import { FRONTEND_SELECTORS } from "../common/constants";
// import { useTranslation } from "react-i18next";
const LoadingBox = styled(Box) `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const LoadingContent = (props) => {
    // const { t } = useTranslation();
    return (_jsx(AppLayout, { content: _jsx(ContentLayout, { children: _jsx(LoadingBox, { "data-testid": "Loading_Box", margin: "n", fontWeight: "light", children: _jsxs(SpaceBetween, { size: "xs", direction: "horizontal", children: [_jsx(Spinner, { variant: "disabled", "data-testid": "Loading_Spinner" }), _jsx(Box, { fontSize: "heading-s", color: "text-body-secondary", children: props.text /*?? t("loadingPage_loadingText_loading")*/ })] }) }) }), navigationHide: true, toolsHide: true, disableContentPaddings: true, headerSelector: "header", footerSelector: "footer" }));
};
export default LoadingContent;
