export var NodeEnvironment;
(function (NodeEnvironment) {
    NodeEnvironment["TEST"] = "test";
    NodeEnvironment["DEVELOPMENT"] = "development";
    NodeEnvironment["BETA"] = "beta";
    NodeEnvironment["GAMMA"] = "gamma";
    NodeEnvironment["PROD"] = "prod";
})(NodeEnvironment || (NodeEnvironment = {}));
export const getNodeEnvironment = (environmentValue = process.env.NODE_ENV || "") => {
    const environment = Object.keys(NodeEnvironment).filter((key) => key === environmentValue.toUpperCase())[0];
    if (!environment) {
        throw new Error(`Node environment [${environmentValue}] is not valid`);
    }
    return NodeEnvironment[environment];
};
