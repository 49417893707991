import { jsx as _jsx } from "react/jsx-runtime";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import "./header.scss";
import { useTranslation } from "react-i18next";
import { getSignInURL, signOut } from "../../auth/authHelpers";
import { ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT } from "../../common/routes";
import { useNavigate } from "react-router-dom";
export const Header = ({ onLanguageChange }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleLanguageChange = (event) => {
        if (event.detail.id) {
            onLanguageChange(event.detail.id);
        }
    };
    const languageOptions = [
        { id: 'ar-SA', text: 'العربية (المملكة العربية السعودية)' },
        { id: 'de-DE', text: 'Deutsch (Deutschland)' },
        { id: 'el-GR', text: 'Ελληνικά (Ελλάδα)' },
        { id: 'en-US', text: 'English (US)' },
        { id: 'es-ES', text: 'Español (España)' },
        { id: 'es-419', text: 'Español (Latinoamérica)' },
        { id: 'fr-FR', text: 'Français (France)' },
        { id: 'he-IL', text: 'עברית (ישראל)' },
        { id: 'id-ID', text: 'Bahasa Indonesia (Indonesia)' },
        { id: 'it-IT', text: 'Italiano (Italia)' },
        { id: 'ja-JP', text: '日本語 (日本)' },
        { id: 'ko-KR', text: '한국어 (대한민국)' },
        { id: 'pl-PL', text: 'Polski (Polska)' },
        { id: 'pt-BR', text: 'Português (Brasil)' },
        { id: 'th-TH', text: 'ไทย (ประเทศไทย)' },
        { id: 'tr-TR', text: 'Türkçe (Türkiye)' },
        { id: 'vi-VN', text: 'Tiếng Việt (Việt Nam)' },
        { id: 'zh-CN', text: '中文 (简体)' },
        { id: 'zh-TW', text: '中文 (繁體)' },
    ];
    const currentLanguage = languageOptions.find(lang => lang.id === i18n.language) || languageOptions[3]; // Default to English if not found
    return (_jsx(TopNavigation, { id: "h", className: "header-wrapper", identity: {
            href: ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT,
            title: t("serviceName"),
            logo: {
                src: "/public/aws-logo.svg",
                alt: "AWS Training & Certification",
            },
        }, utilities: [
            {
                type: "menu-dropdown",
                text: currentLanguage.text,
                title: t("language"),
                onItemClick: handleLanguageChange,
                items: languageOptions,
            },
            {
                type: "button",
                text: t("signOut"),
                onClick: () => {
                    sessionStorage.removeItem("AwsAccountId");
                    sessionStorage.removeItem("AwsAccountJwt");
                    signOut().then(() => window.location.href = getSignInURL());
                },
            },
        ], i18nStrings: {
            searchIconAriaLabel: t("topNavigation_overflowMenuTriggerText"),
            searchDismissIconAriaLabel: t("topNavigation_overflowMenuTriggerText"),
            overflowMenuTriggerText: t("topNavigation_overflowMenuTriggerText"),
            overflowMenuTitleText: t("topNavigation_overflowMenuTitleText"),
            overflowMenuBackIconAriaLabel: t("topNavigation_overflowMenuTriggerText"),
            overflowMenuDismissIconAriaLabel: t("topNavigation_overflowMenuTriggerText"),
        } }));
};
