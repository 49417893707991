import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Form from "@amzn/awsui-components-react/polaris/form";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import { SubscriptionDetailsFormContainer } from "../../components/SubscriptionDetailsFormContainer/SubscriptionDetailsFormContainer";
import { BillingDetailsFormContainer } from "../../components/BillingDetailsFormContainer/BillingDetailsFormContainer";
import { SubscriptionPaymentDetailsContainer } from "../../components/SubscriptionPaymentDetailsContainer/SubscriptionPaymentDetailsContainer";
import { SubscriptionSummaryContainer } from "../../components/SubscriptionSummaryContainer/SubscriptionSummaryContainer";
import { createContext, useEffect, useState } from "react";
import SBTSBreadcrumbGroup from "../../components/BreadcrumbGroup/BreadcrumbGroup";
import { useNavigate } from "react-router-dom";
import { Button, ColumnLayout } from "@amzn/awsui-components-react";
import { ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT } from "../../common/routes";
import { generateAwsSignInUrl } from "../../auth/awsSignIn";
import LoadingContent from "../../components/LoadingContent/LoadingContent";
import { useCreateTeamSubscriptionMutation, useFinalizeTeamSubscriptionMutation } from "../../user/api/mutations";
import { useGetOfferPriceForAccount } from "../../user/api/queries";
export const ConfigureSubscriptionContext = createContext({ numberSeats: 40 });
export const ConfigureSubscriptionPage = (prpos) => {
    const { t } = useTranslation();
    const [awsJwtToken, setAwsJwtToken] = useState();
    const [awsAccountId, setAwsAccountId] = useState();
    const [awsAccountType, setAwsAccountType] = useState();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        businessEmail: '',
        companyName: '',
        endCustomerCompanyName: '',
        endCustomerCountry: '',
        signInMethod: '',
        organizationIdentityProvider: '',
        preferredStartDate: '',
        numberOfSeats: 0,
        orderTotal: '',
        learningAdministrators: [{
                laFirstName: '',
                laLastName: '',
                laEmail: ''
            }]
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [formSubmitError, setFormSubmitError] = useState();
    // const [ createTeamSubscription, { data, loading, error } ] = useCreateTeamSubscriptionMutation()
    const { createTeamSubscription, createTeamSubscriptionLoading, createTeamSubscriptionError } = useCreateTeamSubscriptionMutation(
    // awsAccountId, 
    // (data) => {
    //   console.log(`dataincallback: ${data}`)
    //   sessionStorage.removeItem('AwsAccountId')
    //   sessionStorage.removeItem('AwsAccountJwt')
    //   navigate(ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT, { state: { notification: 'Your team subscription request has been submitted. An AWS expert will reach out to you in 2 business days to configure your subscription.' } })
    // },
    // (error) => {
    //   setFormSubmitError(error)
    // }
    );
    const { finalizeTeamSubscription, finalizeTeamSubscriptionLoading, finalizeTeamSubscriptionError } = useFinalizeTeamSubscriptionMutation(
    // awsAccountId, 
    // (data) => {
    //   console.log(`dataincallback: ${data}`)
    //   sessionStorage.removeItem('AwsAccountId')
    //   sessionStorage.removeItem('AwsAccountJwt')
    //   navigate(ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT, { state: { notification: 'Your team subscription request has been submitted. An AWS expert will reach out to you in 2 business days to configure your subscription.' } })
    // },
    // (error) => {
    //   setFormSubmitError(error)
    // }
    );
    const { data: offerPriceData, error: offerPriceDataError, loading: offerPriceDataLoading } = useGetOfferPriceForAccount();
    console.log(`offerPriceData: ${offerPriceData}, offerPriceDataError: ${offerPriceDataError}, offerPriceDataLoading: ${offerPriceDataLoading}`);
    useEffect(() => {
        const initFromWithDummyData = true;
        if (initFromWithDummyData) {
            const dummyFormData = {
                firstName: 'John',
                lastName: 'Doe',
                businessEmail: 'john@doe.com',
                companyName: 'Amazon',
                endCustomerCompanyName: 'Amazon',
                endCustomerCountry: 'US',
                signInMethod: 'BUILDER_ID',
                organizationIdentityProvider: 'AWS',
                preferredStartDate: '2024-12-12',
                numberOfSeats: 40,
                orderTotal: '1000',
                learningAdministrators: [
                    {
                        laFirstName: 'Jane',
                        laLastName: 'Doe',
                        laEmail: 'jane@doe.com'
                    }
                ],
                price: 300,
                discount: 10,
                userId: "65b84764-2df1-4116-a80b-0b0fa3b5c7df",
                linkedAccountId: "817132693587",
                payerAccountId: "817132693587"
            };
            setFormData(dummyFormData);
        }
        // createTeamSubscription()
    }, []);
    useEffect(() => {
        // Get AWS Sign In JWT token from somewhere
        // Extract and set the AWS Account Id
        const awsAccountId = sessionStorage.getItem("AwsAccountId");
        const awsAccountJwt = sessionStorage.getItem("AwsAccountJwt");
        const awsAccountType = sessionStorage.getItem("AwsAccountType");
        if (awsAccountId && awsAccountJwt) {
            // Use the cookie value
            setAwsAccountId(awsAccountId);
            setAwsJwtToken(awsAccountJwt);
            setAwsAccountType(awsAccountType);
        }
        else {
            console.log(`awsAccountID not found`);
            window.location.assign(generateAwsSignInUrl({ userId: "1234" }));
        }
    });
    const validateForm = () => {
        let isFormValid = true;
        const commonFieldList = [
            'firstName',
            'lastName',
            'businessEmail',
            'companyName',
            'signInMethod',
            'organizationIdentityProvider',
            'preferredStartDate',
            'numberOfSeats',
        ];
        const partnerFieldList = [
            'endCustomerCompanyName',
            'endCustomerCountry',
        ];
        // const fieldList2: [string, any][] = commonFieldList.map((fieldName) => {
        //   return [ fieldName, formData[fieldName] ]
        // })
        const fieldList = [
            ['firstName', formData.firstName],
            ['lastName', formData.lastName],
            ['businessEmail', formData.businessEmail],
            ['companyName', formData.companyName],
            ['signInMethod', formData.signInMethod],
            ['organizationIdentityProvider', formData.organizationIdentityProvider],
            ['preferredStartDate', formData.preferredStartDate],
            ['numberOfSeats', formData.numberOfSeats],
        ];
        if (awsAccountType === 'partner') {
            fieldList.push(['endCustomerCompanyName', formData.endCustomerCompanyName], ['endCustomerCountry', formData.endCustomerCountry]);
        }
        fieldList.map((field) => {
            const [fieldName, fieldValue] = field;
            const errorText = validateFormValue(fieldName, fieldValue);
            // isFormValid = isFormValid || errorText != ''
            isFormValid = isFormValid && !errorText;
            if (!errorText) {
                setErrors((prev) => {
                    return {
                        ...prev,
                        [fieldName]: ''
                    };
                });
            }
            else {
                setErrors((prev) => {
                    return {
                        ...prev,
                        [fieldName]: errorText
                    };
                });
            }
        });
        formData.learningAdministrators.map((la, ind) => {
            const fieldList = [
                ['laEmail', la.laEmail],
                ['laLastName', la.laLastName],
                ['laFirstName', la.laFirstName],
            ];
            fieldList.map((field) => {
                const [fieldName, fieldValue] = field;
                const errorText = validateFormValue(fieldName, fieldValue);
                isFormValid = isFormValid && !errorText;
                if (errorText) {
                    setErrors((prev) => {
                        const learningAdministrators = prev.learningAdministrators || [];
                        learningAdministrators[ind] = errorText;
                        return {
                            ...prev,
                            learningAdministrators
                        };
                    });
                }
                else {
                    setErrors((prev) => {
                        const learningAdministrators = prev.learningAdministrators || [];
                        learningAdministrators[ind] = '';
                        return {
                            ...prev,
                            learningAdministrators
                        };
                    });
                }
            });
        });
        return isFormValid;
    };
    const validateFormValue = (field, value) => {
        let errorText = '';
        switch (field) {
            case 'firstName':
                if (!value) {
                    errorText = t("subscription_form_first_name_required_error_text");
                }
                break;
            case 'lastName':
                if (!value) {
                    errorText = t("subscription_form_last_name_required_error_text");
                }
                break;
            case 'businessEmail':
                if (!value) {
                    errorText = t("subscription_form_business_email_required_error_text");
                }
                if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                    errorText = t("subscription_form_invalid_email_error_text");
                }
                break;
            case 'companyName':
                if (!value) {
                    errorText = t("subscription_form_company_name_required_error_text");
                }
                break;
            case 'endCustomerCompanyName':
                if (!value) {
                    errorText = t("subscription_form_end_customer_company_name_required_error_text");
                }
                break;
            case 'endCustomerCountry':
                if (!value) {
                    errorText = t("subscription_form_end_customer_country_required_error_text");
                }
                break;
            case 'numberOfSeats':
                if (!value) {
                    errorText = t("subscription_form_number_of_seats_required_error_text");
                }
                break;
            case 'signInMethod':
                if (!value) {
                    errorText = t("subscription_form_sign_in_method_required_error_text");
                }
                break;
            case 'preferredStartDate':
                if (value) {
                    const minAllowedDaysFromToday = 10;
                    const preferredStartDate = new Date(value);
                    const closestAllowedDate = new Date(new Date().setDate(new Date().getDate() + minAllowedDaysFromToday));
                    if (preferredStartDate.getTime() < closestAllowedDate.getTime()) {
                        errorText = t("subscription_form_sign_in_method_required_error_text", { minAllowedDaysFromToday });
                        // errorText = `Preferred start date must be after ${closestAllowedDate.toUTCString()}`
                    }
                }
                break;
            case 'laFirstName':
                if (!value) {
                    errorText = t("subscription_form_la_first_name_required_error_text");
                }
                break;
            case 'laLastName':
                if (!value) {
                    errorText = t("subscription_form_la_last_name_required_error_text");
                }
                break;
            case 'laEmail':
                if (!value) {
                    errorText = t("subscription_form_la_email_required_error_text");
                }
                // if(e.detail.value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, 4}$/i.test(e.detail.value)) {
                if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                    errorText = t("subscription_form_invalid_email_error_text");
                }
                break;
            default:
                break;
        }
        return errorText;
    };
    const handleFormDataChange = (field, e, index) => {
        // console.log(`handing formdata change: ${field} : ${index} : ${JSON.stringify(e)}`)
        // validateFormField(field, e, index);
        let value = e.detail.value;
        if (field === 'endCustomerCountry') {
            value = e.detail.selectedOption.value;
        }
        const errorText = validateFormValue(field, value);
        if (field === 'laFirstName' || field === 'laLastName' || field === 'laEmail') {
            const learningAdministrators = formData.learningAdministrators;
            learningAdministrators[index][field] = value;
            setFormData({
                ...formData,
                learningAdministrators: learningAdministrators
            });
            if (errorText) {
                setErrors((prev) => {
                    const learningAdministrators = prev.learningAdministrators || [];
                    learningAdministrators[index] = errorText;
                    return {
                        ...prev,
                        learningAdministrators
                    };
                });
            }
            else {
                setErrors((prev) => {
                    const learningAdministrators = prev.learningAdministrators || [];
                    learningAdministrators[index] = '';
                    return {
                        ...prev,
                        learningAdministrators
                    };
                });
            }
            return;
        }
        setFormData({
            ...formData,
            [field]: value
        });
        if (!errorText) {
            setErrors((prev) => {
                return {
                    ...prev,
                    [field]: ''
                };
            });
        }
        else {
            setErrors((prev) => {
                return {
                    ...prev,
                    [field]: errorText
                };
            });
        }
    };
    const handleAddLearningAdmin = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            learningAdministrators: [...formData.learningAdministrators, {
                    laFirstName: '',
                    laLastName: '',
                    laEmail: ''
                }]
        });
    };
    const handleTeamSubscriptionSubmit = (e) => {
        e.preventDefault();
        console.log(`formdataforsubmit: ${JSON.stringify(formData)}`);
        if (validateForm()) {
            // const formData = new FormData(e.target as HTMLFormElement);
            // const payload = Object.fromEntries(formData)
            // console.log(`formData: ${JSON.stringify(payload)}`)
            // console.log(`Removing AwsAccountId and AwsAccountJwt values from session storage`)
            const finalizeTeamSubscriptionData = formData;
            const laList = finalizeTeamSubscriptionData.learningAdministrators.map((la) => {
                return {
                    firstName: la.laFirstName,
                    lastName: la.laLastName,
                    email: la.laEmail
                };
            });
            finalizeTeamSubscriptionData.learningAdministrators = laList;
            delete finalizeTeamSubscriptionData['orderTotal'];
            finalizeTeamSubscriptionData.preferredStartDate = formData.preferredStartDate
                ? new Date(formData.preferredStartDate).toISOString()
                : undefined;
            finalizeTeamSubscription({
                onCompleted: () => {
                    // alert('success')
                    // sessionStorage.removeItem('AwsAccountId')
                    // sessionStorage.removeItem('AwsAccountJwt')
                    navigate(ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT, { state: { notification: t("subscription_form_submit_success_notification_text") } });
                },
                onError: (error) => {
                    // alert('failure')
                    console.log(error);
                    setFormSubmitError(error);
                },
                variables: { input: finalizeTeamSubscriptionData }
            });
            // const { createTeamSubscription, createTeamSubscriptionLoading, createTeamSubscriptionError } =  useCreateTeamSubscriptionMutation(awsAccountId, (data) => {
            //   console.log(`dataincallback: ${data}`)
            // })                                          
            // axios
            //   .post('/subscriptionrequest/create', payload)
            //   .then((response) => {
            //     console.log(`/subscriptionrequest/create succeeded;`);
            //     alert('success')
            //     // Remove AWS account id and JWT from session. If user buys another subscription they will be requested to sign in AWS again
            //     // sessionStorage.removeItem('AwsAccountId')
            //     // sessionStorage.removeItem('AwsAccountJwt')
            //     navigate(ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT, { state: { notification: 'Your team subscription request has been submitted. An AWS expert will reach out to you in 2 business days to configure your subscription.' } })
            //   })
            //   .catch((error) => {
            //     console.log(`/subscriptionrequest/create failed`)
            //     console.log(error);
            //   })
        }
        else {
            console.log(`Form validation failed`);
        }
    };
    // console.log(`formSubmitError: ${JSON.stringify(formSubmitError)}`)
    // console.log(`createTeamSubscriptionLoading: ${JSON.stringify(createTeamSubscriptionLoading)}`)
    // console.log(`createTeamSubscriptionError: ${JSON.stringify(createTeamSubscriptionError)}`)
    if (!awsAccountId || offerPriceDataLoading) {
        return _jsx(LoadingContent, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(SBTSBreadcrumbGroup, { items: [
                    { text: "Subscription management", href: "/" },
                    {
                        text: "Subscription order",
                        href: "#"
                    }
                ] }), _jsx("form", { onSubmit: handleTeamSubscriptionSubmit, "aria-label": "form", children: _jsxs(SpaceBetween, { size: "l", children: [_jsxs(ColumnLayout, { columns: 2, children: [_jsx(Box, { variant: "h1", children: t("subscription_form_header") }), _jsx(Box, { float: "right", children: _jsxs(SpaceBetween, { size: "xs", direction: "horizontal", children: [_jsx(Button, { variant: "normal", onClick: e => navigate(ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT), children: t("cancel_text") }), _jsx(Button, { variant: "primary", children: t("subscription_form_submit_button_text") })] }) })] }), _jsx(Form, { children: _jsxs(Grid, { gridDefinition: [{ colspan: 7 }, { colspan: 5 }], children: [_jsx(Box, { children: _jsxs(SpaceBetween, { size: "l", children: [_jsx(BillingDetailsFormContainer, { formData: formData, onFormDataChange: handleFormDataChange, errors: errors }), _jsx(SubscriptionDetailsFormContainer, { formData: formData, onFormDataChange: handleFormDataChange, onAddLearningAdmin: handleAddLearningAdmin, errors: errors, awsAccountType: awsAccountType, offerPriceData: offerPriceData }), _jsx(SubscriptionPaymentDetailsContainer, { awsAccountId: awsAccountId })] }) }), _jsx(Box, { children: _jsx(SubscriptionSummaryContainer, { awsAccountId: awsAccountId, numberOfSeats: +formData.numberOfSeats, offerPriceData: offerPriceData, awsAccountType: awsAccountType }) })] }) }), _jsx(Box, {})] }) })] }));
};
export const ConfigureSubscriptionPageLayout = (props) => {
    return (_jsx(AppLayout, { content: _jsx(ConfigureSubscriptionPage, { setNotification: props.setNotification }), toolsHide: true, navigationHide: true }));
};
