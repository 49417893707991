import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import TextRequest from "@amzn/awsui-components-react/polaris/text-content";
import "./Footer.scss";
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import CookieConsentManager from "../../cookieManagement/CookieConsentManager";
const currentYear = new Date().getFullYear().toString();
export function Footer() {
    const { t } = useTranslation();
    const cookieConsentManager = new CookieConsentManager();
    return (_jsx("div", { className: "footer-wrapper", id: "footer", children: _jsx("footer", { className: "footer", children: _jsx("nav", { children: _jsx(TextRequest, { children: _jsx("div", { children: _jsxs(SpaceBetween, { direction: "horizontal", size: "l", children: [_jsx("div", { className: "footer__actions", children: _jsx(Box, { children: _jsx(Link, { target: "_blank", external: true, color: "inverted", onClick: () => {
                                                window.open();
                                            }, children: t("footer_feedback") }) }) }), _jsx(Box, { children: _jsx("div", { className: "divider", children: "|" }) }), _jsx("div", { className: "footer__actions", children: _jsx(Box, { children: _jsx(Link, { "data-testid": "legal_link", color: "inverted", onClick: () => {
                                                window.open();
                                            }, children: t("footer_legal") }) }) }), _jsx(Box, { children: _jsx("div", { className: "divider", children: "|" }) }), _jsx("div", { className: "footer__actions", children: _jsx(Box, { children: _jsx(Link, { "data-testid": "privacy_link", color: "inverted", onClick: () => {
                                                window.open();
                                            }, children: t("footer_privacy") }) }) }), _jsx(Box, { children: _jsx("div", { className: "divider", children: "|" }) }), _jsx("div", { className: "footer__actions", children: _jsx(Box, { children: _jsx(Link, { "data-testid": "cookie_policy", color: "inverted", onFollow: cookieConsentManager.customizeCookies, children: t("footer_cookiePolicy") }) }) }), _jsx(Box, { children: _jsx("div", { className: "divider", children: "|" }) }), _jsx("div", { className: "footer__actions", children: t("footer_copyright", { year: currentYear }) })] }) }) }) }) }) }));
}
