import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { useSubscriptionItems } from "../../hooks/useSubscriptionItems";
import { useNavigate } from 'react-router-dom';
import { generateAwsSignInUrl } from "../../auth/awsSignIn";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import { SpmsIdModal } from "../SpmsIdModal/SpmsIdModal";
import { ROUTE_SKILLBUILDER_SUBSCRIPTION_REQUEST } from "../../common/routes";
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useTranslation } from "react-i18next";
// import { Table, TextFilter, Button, Box } from "@cloudscape-design/components";
// Add this before the Table component
{ /* <TextFilter
  filteringText={filteringText}
  filteringPlaceholder="Find subscriptions"
  filteringAriaLabel="Filter subscriptions"
  onChange={({ detail }) => setFilteringText(detail.filteringText)}
/> */
}
const columnDefinitions = [
    {
        id: "submitted_by",
        header: "Submitted by",
        cell: (item) => `${item.firstName} ${item.lastName}`,
        sortingField: "submitted_by",
        isRowHeader: true
    },
    {
        id: "subscription_request_id",
        header: "Subscription request ID",
        cell: (item) => item.id,
        sortingField: "subscription_request_id",
        isRowHeader: true
    },
    {
        id: "order_date",
        header: "Order date",
        cell: (item) => new Date(item.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
        sortingField: "order_date",
        isRowHeader: true
    },
    {
        id: "status",
        header: "Status",
        cell: (item) => _jsx(StatusIndicator, { type: item.status === 'approved' ? 'success' : 'stopped', children: item.status }),
        sortingField: "status",
        isRowHeader: true
    }
];
const defaultContentDisplayPreferences = {
    contentDisplay: [
        { id: "submitted_by", visible: true },
        { id: "subscription_request_id", visible: true },
        { id: "order_date", visible: true },
        { id: "order_number", visible: true },
        { id: "subscription_status", visible: true },
        { id: "start_date", visible: true },
        { id: "end_date", visible: true },
        { id: "seats", visible: true },
        { id: "sign_in_method", visible: true },
        { id: "end_customer_name", visible: true },
        { id: "end_customer_country", visible: true },
        { id: "order_total", visible: true },
        { id: "billing_aws_account_id", visible: true },
    ]
};
export const SubscriptionReqTable = () => {
    const [subscriptionCollection, loading] = useSubscriptionItems();
    const [awsAccountModalVisible, setAwsAccountModalVisible] = React.useState(false);
    const [spmsModalVisible, setSpmsModalVisible] = React.useState(false);
    const [contentDisplayPreferences, setContentDisplayPreferences] = React.useState(defaultContentDisplayPreferences);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(subscriptionCollection || [], {
        filtering: {
            empty: (_jsx(Box, { children: "No instances" })),
            noMatch: (_jsx(Box, { children: "No matches" })),
            filteringFunction: (item, filteringText, filteringFields) => {
                console.log(`filteringinput: item:${JSON.stringify(item)}; filteringText: ${filteringText}; filteringFields: ${JSON.stringify(filteringFields)}`);
                if (!filteringText) {
                    return true;
                }
                const machingRow = Object.values(item).find((value) => {
                    if (typeof value === 'string' && value.toLowerCase().includes(filteringText.toLowerCase())) {
                        return true;
                    }
                });
                return !!machingRow;
            }
        },
        // sorting: {},
        // pagination: {
        //   pageSize: preferences.pageSize
        // },
        // selection: {}
    });
    const getColumnVisibility = (columnName) => {
        const column = contentDisplayPreferences.contentDisplay.find((column) => column.id === columnName);
        return column.visible;
    };
    const handleStartNewSubscriptionButtonClick = (id) => {
        if (id === 'partner') {
            console.log('Do partner thing');
            setSpmsModalVisible(true);
        }
        else {
            console.log('Do regular thing');
            setAwsAccountModalVisible(true);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Table, { renderAriaLive: ({ firstIndex, lastIndex, totalItemsCount }) => 
                // `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
                t("subscription_request_table_aria_description", { firstIndex, lastIndex, totalItemsCount }), 
                // onSelectionChange={({ detail }) =>
                //   setSelectedItems(detail.selectedItems)
                // }
                // selectedItems={selectedItems}
                // ariaLabels={{
                //   selectionGroupLabel: "Items selection",
                //   allItemsSelectionLabel: () => "select all",
                //   itemSelectionLabel: ({ selectedItems }, item) =>
                //     item.name
                // }}
                totalItemsCount: subscriptionCollection?.length || 0, columnDefinitions: [
                    {
                        id: "id",
                        header: "ID",
                        cell: (item) => item.id,
                        sortingField: "id"
                    },
                    {
                        id: "submitted_by",
                        header: `${t("subscription_request_column_header_submitted_by")}`,
                        cell: (item) => `${item.firstName} ${item.lastName}`,
                        sortingField: "submitted_by",
                        sortingComparator: (a, b) => {
                            console.log(`submitted_bycomparator: a:${JSON.stringify(a)}; b:${JSON.stringify(b)}`);
                            return 1;
                        }
                    },
                    {
                        id: "subscription_request_id",
                        header: `${t("subscription_request_column_header_subscription_request_id")}`,
                        cell: (item) => item.id,
                        sortingField: "subscription_request_id",
                        isRowHeader: true
                    },
                    {
                        id: "order_date",
                        header: `${t("subscription_request_column_header_order_date")}`,
                        // cell: (item: any) => new Date(item.order_date).toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'}),
                        cell: (item) => new Date(item.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
                        sortingField: "order_date",
                        sortingComparator: (a, b) => {
                            console.log(`comparator: a:${JSON.stringify(a)}; b:${JSON.stringify(b)}`);
                            return 1;
                            // return sortingColumn.sortingDirection === "descending" 
                            //     ? new Date(b[field]).getTime() - new Date(a[field]).getTime()
                            //     : new Date(a[field]).getTime() - new Date(b[field]).getTime();
                        }
                    },
                    {
                        id: "order_number",
                        header: `${t("subscription_request_column_header_order_number")}`,
                        cell: (item) => item.order_number || '-',
                        sortingField: "order_number"
                    },
                    {
                        id: "subscription_status",
                        header: `${t("subscription_request_column_header_subscription_status")}`,
                        cell: item => {
                            if (item.status === "Request started") {
                                return (_jsx(StatusIndicator, { type: "pending", children: item.status }));
                            }
                            else if (item.status === "Canceled") {
                                return (_jsx(StatusIndicator, { type: "stopped", children: item.status }));
                            }
                            return (_jsx(StatusIndicator, { type: "success", children: item.status }));
                        },
                        sortingField: "subscription_status"
                    },
                    {
                        id: "start_date",
                        header: `${t("subscription_request_column_header_start_date")}`,
                        cell: item => new Date(item.subscriptionStartDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
                        sortingField: "start_date"
                    },
                    {
                        id: "end_date",
                        header: `${t("subscription_request_column_header_end_date")}`,
                        cell: item => new Date(item.subscriptionEndDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
                        sortingField: "end_date"
                    },
                    {
                        id: "seats",
                        header: `${t("subscription_request_column_header_seats")}`,
                        cell: item => item.numberOfSeats,
                        sortingField: "seats"
                    },
                    {
                        id: "sign_in_method",
                        header: `${t("subscription_request_column_header_sign_in_method")}`,
                        cell: item => item.authenticationMethod,
                        sortingField: "sign_in_method"
                    },
                    {
                        id: "end_customer_name",
                        header: `${t("subscription_request_column_header_end_customer_name")}`,
                        cell: item => item.endCustomerName,
                        sortingField: "end_customer_name"
                    },
                    {
                        id: "end_customer_country",
                        header: `${t("subscription_request_column_header_end_customer_country")}`,
                        cell: item => item.endCustomerCountry,
                        sortingField: "end_customer_country"
                    },
                    {
                        id: "order_total",
                        header: `${t("subscription_request_column_header_order_total")}`,
                        cell: item => item.order_total,
                        sortingField: "order_total"
                    },
                    {
                        id: "billing_aws_account_id",
                        header: `${t("subscription_request_column_header_billing_aws_account_id")}`,
                        cell: item => item.linkedAccountId,
                        sortingField: "billing_aws_account_id"
                    }
                ], columnDisplay: [
                    { id: "submitted_by", visible: getColumnVisibility('submitted_by') },
                    { id: "subscription_request_id", visible: getColumnVisibility('subscription_request_id') },
                    { id: "order_date", visible: getColumnVisibility('order_date') },
                    { id: "order_number", visible: getColumnVisibility('order_number') },
                    { id: "subscription_status", visible: getColumnVisibility('subscription_status') },
                    { id: "start_date", visible: getColumnVisibility('start_date') },
                    { id: "end_date", visible: getColumnVisibility('end_date') },
                    { id: "seats", visible: getColumnVisibility('seats') },
                    { id: "sign_in_method", visible: getColumnVisibility('sign_in_method') },
                    { id: "end_customer_name", visible: getColumnVisibility('end_customer_name') },
                    { id: "end_customer_country", visible: getColumnVisibility('end_customer_country') },
                    { id: "order_total", visible: getColumnVisibility('order_total') },
                    { id: "billing_aws_account_id", visible: getColumnVisibility('billing_aws_account_id') },
                ], enableKeyboardNavigation: true, 
                // items={subscriptionCollection}
                items: items, loadingText: t("subscription_request_table_loading_text"), loading: loading, trackBy: "subscription_request_id", empty: _jsx(Box, { margin: { vertical: "xs" }, textAlign: "center", color: "inherit", children: _jsxs(SpaceBetween, { size: "s", children: [_jsx("b", { children: t("subscription_request_no_subscriptions_text") }), _jsx(Box, { variant: "p", children: t("subscription_request_no_subscriptions_description") }), _jsx(ButtonDropdown, { onItemClick: ({ detail }) => handleStartNewSubscriptionButtonClick(detail.id), items: [
                                    { text: `${t('subscription_request_regular_customer_option_text')}`, id: "regular", disabled: false },
                                    { text: `${t('subscription_request_partner_customer_option_text')}`, id: "partner", disabled: false },
                                ], variant: "normal", children: t("subscription_request_start_new_subscription_button_text") })] }) }), header: _jsx(Header, { counter: `(${subscriptionCollection?.length || 0})`, actions: _jsx(SpaceBetween, { direction: "horizontal", size: "xs", children: _jsx(ButtonDropdown, { onItemClick: ({ detail }) => handleStartNewSubscriptionButtonClick(detail.id), items: [
                                { text: `${t('subscription_request_regular_customer_option_text')}`, id: "regular", disabled: false },
                                { text: `${t('subscription_request_partner_customer_option_text')}`, id: "partner", disabled: false },
                            ], variant: "primary", children: t("subscription_request_start_new_subscription_button_text") }) }), children: t("subscription_request_table_header") }), filter: _jsx(TextFilter, { ...filterProps, filteringPlaceholder: t("subscription_request_filter_placeholder_text") }), preferences: _jsx(CollectionPreferences, { title: t("preferences_text"), confirmLabel: t("confirm_text"), cancelLabel: t("cancel_text"), onConfirm: ({ detail }) => {
                        console.log(`prefrences updated; ${JSON.stringify(detail)}`);
                        setContentDisplayPreferences(detail);
                    }, preferences: contentDisplayPreferences, pageSizePreference: {
                        title: t("subscription_request_table_page_size_header"),
                        options: [
                            { value: 10, label: "10 resources" },
                            { value: 20, label: "20 resources" }
                        ]
                    }, 
                    // wrapLinesPreference={{}}
                    // stripedRowsPreference={{}}
                    // contentDensityPreference={{}}
                    contentDisplayPreference: {
                        title: t("subscription_request_table_column_preferences_header"),
                        description: t("subscription_request_table_column_preferences_description"),
                        options: [
                            { id: "submitted_by", label: t("subscription_request_column_header_submitted_by") },
                            { id: "subscription_request_id", label: t("subscription_request_column_header_subscription_request_id") },
                            { id: "order_date", label: t("subscription_request_column_header_order_date") },
                            {
                                id: "order_number",
                                label: t("subscription_request_column_header_order_number"),
                                alwaysVisible: true
                            },
                            { id: "subscription_status", label: t("subscription_request_column_header_subscription_status") },
                            { id: "start_date", label: t("subscription_request_column_header_start_date") },
                            { id: "end_date", label: t("subscription_request_column_header_end_date") },
                            { id: "seats", label: t("subscription_request_column_header_seats") },
                            { id: "sign_in_method", label: t("subscription_request_column_header_sign_in_method") },
                            { id: "end_customer_name", label: t("subscription_request_column_header_end_customer_name") },
                            { id: "end_customer_country", label: t("subscription_request_column_header_end_customer_country") },
                            { id: "order_total", label: t("subscription_request_column_header_order_total") },
                            { id: "billing_aws_account_id", label: t("subscription_request_column_header_billing_aws_account_id") },
                        ]
                    } }) }), awsAccountModalVisible &&
                _jsx(Modal, { className: "awsui-visual-refresh", onDismiss: () => setAwsAccountModalVisible(false), visible: awsAccountModalVisible, footer: _jsx(Box, { float: "right", children: _jsxs(SpaceBetween, { direction: "horizontal", size: "xs", children: [_jsx(Button, { variant: "link", onClick: () => setAwsAccountModalVisible(false), children: "Cancel" }), _jsx(Button, { variant: "primary", iconAlign: "right", iconName: "external", onClick: () => {
                                        // If AwsAccountId found in session store, the user already went through the authorization process. Can navigate them directly to the configure subscription page
                                        const awsAccountId = sessionStorage.getItem('AwsAccountId');
                                        if (awsAccountId) {
                                            navigate(ROUTE_SKILLBUILDER_SUBSCRIPTION_REQUEST);
                                        }
                                        else {
                                            window.location.href = generateAwsSignInUrl({ userId: "1234" });
                                        }
                                    }, children: t("subscription_request_aws_login_button_text") })] }) }), header: t("subscription_request_aws_login_modal_header"), children: _jsxs(SpaceBetween, { size: "s", children: [_jsx(Box, { children: t("subscription_request_aws_login_modal_description") }), _jsx(Alert, { statusIconAriaLabel: "Info", children: _jsxs(Grid, { gridDefinition: [{ colspan: 7 }, { colspan: 5 }], children: [_jsxs(Box, { children: [_jsx(Box, { variant: "h5", padding: 'n', children: t("subscription_request_aws_login_modal_info_header") }), t("subscription_request_aws_login_modal_info_description")] }), _jsx(Box, { float: "right", children: _jsx(Button, { iconAlign: "right", iconName: "external", children: t("subscription_request_aws_login_modal_create_account_button_text") }) })] }) })] }) }), _jsx(SpmsIdModal, { spmsModalVisible: spmsModalVisible, setSpmsModalVisible: setSpmsModalVisible, setAwsAccountModalVisible: setAwsAccountModalVisible })] }));
};
