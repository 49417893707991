import { AwsRum } from "aws-rum-web";
import { getCurrentRumStage, RUM_APP_ENDPOINT, RUM_APP_REGION, RUM_APP_TELEMETRIES, RUM_APP_VERSION, } from "./rumConstants";
export const rumInit = (allowCookies = false) => {
    const rumStage = getCurrentRumStage();
    try {
        const config = {
            ...rumStage.rumStageConfig,
            endpoint: RUM_APP_ENDPOINT,
            telemetries: RUM_APP_TELEMETRIES,
            allowCookies: allowCookies,
        };
        new AwsRum(rumStage.appId, RUM_APP_VERSION, RUM_APP_REGION, config);
    }
    catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
};
