export var CookieCategory;
(function (CookieCategory) {
    CookieCategory["Essential"] = "Essential";
    CookieCategory["Performance"] = "Performance";
    CookieCategory["Functional"] = "Functional";
    CookieCategory["Advertising"] = "Advertising";
})(CookieCategory || (CookieCategory = {}));
export const CookieNames = {
    CLOUDWATCH_RUM_S: "cwr_s",
    CLOUDWATCH_RUM_U: "cwr_u",
};
