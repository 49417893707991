/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AWSCShortbread } from "@amzn/shortbread";
import { CookieCategory, CookieNames } from "./cookieType";
import remove from "js-cookie";
import get from "js-cookie";
import { rumInit } from "../clientMonitoring/rumClient";
import { FRONTEND_SELECTORS } from "../common/constants";
import { getApplicationURL } from "../common/utilityFunctions";
class CookieConsentManager {
    // Shortbread library usage https://w.amazon.com/bin/view/Shortbread/usage
    shortBread;
    categoryMap = new Map([
        ["essential", CookieCategory.Essential],
        ["performance", CookieCategory.Performance],
        ["functional", CookieCategory.Functional],
        ["advertising", CookieCategory.Advertising],
    ]);
    constructor() {
        this.shortBread = AWSCShortbread({
            language: "en-US",
            parent: window.document.querySelector(FRONTEND_SELECTORS.APP),
            domain: this.getCookieDomain(),
            registry: {
                [CookieNames.CLOUDWATCH_RUM_S]: { category: "functional" },
                [CookieNames.CLOUDWATCH_RUM_U]: { category: "functional" },
            },
            onConsentChanged: (consentCategories) => {
                this.manageRUMCookies();
            },
        });
    }
    /**
     * Check for Cookie Consent using the Shortbread UI lib.
     * This is intended to be called once for every page, after the initial load.
     */
    checkForCookieConsent = () => {
        this.shortBread.checkForCookieConsent();
    };
    /**
     * Customize using the Shortbread UI lib.
     * This is intended to be called when the user clicks on Cookie preferences in the footer.
     */
    customizeCookies = () => {
        this.shortBread.customizeCookies();
    };
    /**
     * Returns whether a specific Cookie has consent.
     */
    cookieHasConsent = (cookieName) => {
        return this.shortBread.hasConsent(cookieName);
    };
    /**
     * Returns whether a Cookie Category has consent.
     */
    hasCookieCategoryConsent = (cookieCategory) => {
        if (!this.isConsentCookieSet()) {
            return false;
        }
        return this.getConsentCookieList().includes(cookieCategory);
    };
    /**
     * Checks if consent cookie is set.
     */
    isConsentCookieSet = () => {
        const consentCookie = this.shortBread.getConsentCookie();
        return !!consentCookie;
    };
    /**
     * Gets list of cookie categories that have consent.
     */
    getConsentCookieList = () => {
        const consentCookie = this.shortBread.getConsentCookie();
        if (!consentCookie) {
            return [];
        }
        return (Object.keys(consentCookie)
            // @ts-ignore
            .filter((category) => consentCookie[category])
            .map((c) => this.categoryMap.get(c)));
    };
    /**
     * Places Cloudwatch RUM cookies if functional cookies are allowed and cookies are not present.
     * If functional cookies not permitted, and Cloudwatch Rum cookies present, removes those cookies.
     */
    manageRUMCookies = () => {
        if (this.hasCookieCategoryConsent(CookieCategory.Functional) &&
            (!get.get(CookieNames.CLOUDWATCH_RUM_S) || !get.get(CookieNames.CLOUDWATCH_RUM_U))) {
            rumInit(true);
        }
        if (!this.hasCookieCategoryConsent(CookieCategory.Functional) &&
            (get.get(CookieNames.CLOUDWATCH_RUM_S) || get.get(CookieNames.CLOUDWATCH_RUM_U))) {
            remove.remove(CookieNames.CLOUDWATCH_RUM_S, {
                path: "/",
                domain: window.location.hostname,
            });
            remove.remove(CookieNames.CLOUDWATCH_RUM_U, {
                path: "/",
                domain: window.location.hostname,
            });
        }
    };
    /**
     * Determines the domain to set cookies on, based on the environment. Beta and Gamma are using
     * the same domain as previously setting cookies on Gamma would always override the cookies set on
     * Beta as Beta was a subdomain of Gamma.
     */
    getCookieDomain = () => {
        return new URL(getApplicationURL()).hostname;
    };
}
export default CookieConsentManager;
