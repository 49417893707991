import { getNodeEnvironment, NodeEnvironment } from "./nodeEnvironment";
import { APPLICATION_URL } from "./constants";
export const getApplicationURL = () => {
    const nodeEnvironment = getNodeEnvironment();
    switch (nodeEnvironment) {
        case NodeEnvironment.BETA: {
            return APPLICATION_URL.BETA;
        }
        case NodeEnvironment.GAMMA: {
            return APPLICATION_URL.GAMMA;
        }
        case NodeEnvironment.PROD: {
            return APPLICATION_URL.PROD;
        }
        default: {
            return APPLICATION_URL.DEVELOPMENT;
        }
    }
};
