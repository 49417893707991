export class ApplicationUser {
    id;
    email;
    isLoggedIn;
    role;
    constructor(initProps) {
        this.email = initProps.email;
        this.isLoggedIn = initProps.isLoggedIn;
        this.role = initProps.role;
        this.id = initProps.id;
    }
}
