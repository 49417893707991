import { gql, useLazyQuery, useQuery } from "@apollo/client";
export const GET_CURRENT_USER = gql `
  query GetCurrentUser {
    currentUser {
      id
      emailAddress
      firstName
      lastName
      status
      userRole
      auditMetadata {
        createdAt
      }
      gandalfDetails {
        audiencePath
      }
    }
  }
`;
export const GET_SUBSCRIPTION_REQUEST_BY_AWS_ACCOUNT_ID = gql `
  query subscriptionRequestByAWSAccountId ($awsAccountId: ID!) {
    subscriptionRequestByAWSAccountId (id: $awsAccountId) {
      id
      seats
      submitted_by
      order_date
      order_number
      subscription_status
      start_date
      end_date
    }
  }
`;
export const GET_SUBSCRIPTION_REQUEST_BY_USER_ID = gql `
  query subscriptionRequestByUserId ($userId: ID!) {
    subscriptionRequestsByUserId(userId: $userId) {
      nodes {
        id
        createdAt
        createdBy
        endCustomerCompanyName
        status
        subscriptionEndDate
        subscriptionStartDate
        userId
        numberOfSeats
        lastName
        firstName
        linkedAccountId
        organizationIdentityProvider
      }
    }
  }
`;
export const GET_SPMS_ID_VALIDATION = gql `
  query spmsIdValidation ($id: Int!) {
    validateATPUserBySPMSId(id: $id)
  }
`;
export const GET_OFFER_PRICE_FOR_ACCOUNT = gql `
  query getPayerAccountOfferPrice {
    getPayerAccountOfferPrice(
      input: {userEmail: "nmnydv+otp@amazon.com", accountId: "014114563954", userId: "65b84764-2df1-4116-a80b-0b0fa3b5c7df"}
    ) {
      payerAccountId
      pricePerSeat
      resellAuthorizedTerritories
      discountList {
        percentageDiscount
        priceThreshold
      }
    }
  }
`;
export const useGetOfferPriceForAccount = () => {
    return useQuery(GET_OFFER_PRICE_FOR_ACCOUNT);
};
export const useGetCurrentUser = () => {
    return useQuery(GET_CURRENT_USER, {});
};
export const useSubscriptionRequestByUserId = (userId) => {
    return useQuery(GET_SUBSCRIPTION_REQUEST_BY_USER_ID, {
        variables: { userId }
    });
};
export const validateATPUserBySPMSId = (spmsId) => {
    return useQuery(GET_SPMS_ID_VALIDATION, {
        variables: { id: spmsId }
    });
};
export const useGetSpmsIdValidation = (spmsId, successCallback, errorCallback) => {
    return useLazyQuery(GET_SPMS_ID_VALIDATION, {
        variables: { id: spmsId },
        onCompleted: (data) => {
            console.log('spmsvalidationdata', data);
            successCallback && successCallback(data?.validateATPUserBySPMSId);
        },
        onError: (error) => {
            errorCallback && errorCallback(error);
        }
    });
};
