import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import "./SubscriptionSetup.scss";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { useTranslation } from "react-i18next";
export function SubscriptionSetup() {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(true);
    return (_jsx(ExpandableSection, { headerText: t("subscription_setup_header"), variant: "container", expanded: isExpanded, defaultExpanded: isExpanded, onChange: ({ detail }) => setIsExpanded(detail.expanded), children: _jsx(SpaceBetween, { size: "m", direction: "horizontal", children: _jsxs(ColumnLayout, { columns: 3, children: [_jsxs(Box, { children: [_jsx("img", { src: "/public/submit-request-form.svg" }), _jsx(Box, { variant: "h3", children: t('subscription_setup_step_1_title') }), _jsx(Box, { variant: "p", children: t('subscription_setup_step_1_description') })] }), _jsxs(Box, { children: [_jsx("img", { src: "/public/onboard-to-sb.svg" }), _jsx(Box, { variant: "h3", children: t('subscription_setup_step_2_title') }), _jsx(Box, { variant: "p", children: t('subscription_setup_step_2_description') })] }), _jsxs(Box, { children: [_jsx("img", { src: "/public/start-learning.svg" }), _jsx(Box, { variant: "h3", children: t('subscription_setup_step_3_title') }), _jsx(Box, { variant: "p", children: t('subscription_setup_step_3_description') })] })] }) }) }));
}
