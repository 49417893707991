import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import DatePicker from "@amzn/awsui-components-react/polaris/date-picker";
import { Fragment } from "react";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import Select from "@amzn/awsui-components-react/polaris/select";
const PartnerFields = ({ formData, onFormDataChange, errors, resellAuthorizedTerritories }) => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsxs(SpaceBetween, { direction: "vertical", size: "l", children: [_jsx(FormField, { label: t("subscription_form_end_customer_company_name_label"), description: t("subscription_form_end_customer_company_name_description"), errorText: errors.endCustomerCompanyName, children: _jsx(Input, { value: formData.endCustomerCompanyName, onChange: (e) => onFormDataChange('endCustomerCompanyName', e), name: "endCustomerCompanyName", placeholder: t("subscription_form_end_customer_company_name_label") }) }), _jsx(FormField, { label: t("subscription_form_end_customer_country_label"), description: t("subscription_form_end_customer_country_description"), errorText: errors.endCustomerCountry, children: _jsx(Select, { name: "endCustomerCountry", placeholder: t("subscription_form_end_customer_country_placeholder"), selectedOption: formData.endCustomerCountry ? { label: formData.endCustomerCountry, value: formData.endCustomerCountry } : null, onChange: (e) => onFormDataChange('endCustomerCountry', e), options: resellAuthorizedTerritories?.map((territory) => {
                            return { label: territory, value: territory };
                        }) || [
                            { label: "US", value: "US" },
                            { label: "India", value: "India" },
                        ] }) })] }) }));
};
export const SubscriptionDetailsFormContainer = (props) => {
    const { t } = useTranslation();
    return (_jsx(Container, { header: _jsx(Header, { variant: "h2", children: t("subscription_form_subscription_details_header") }), children: _jsxs(SpaceBetween, { direction: "vertical", size: "l", children: [props.awsAccountType && props.awsAccountType === 'partner' &&
                    _jsx(PartnerFields, { formData: props.formData, onFormDataChange: props.onFormDataChange, errors: props.errors, resellAuthorizedTerritories: props.offerPriceData.resellAuthorizedTerritories }), _jsx(FormField, { label: t("subscription_form_number_of_seats_label"), errorText: props.errors.numberOfSeats, children: _jsx(Input, { type: "number", name: "numberOfSeats", value: `${props.formData.numberOfSeats}`, onChange: (e) => props.onFormDataChange('numberOfSeats', e), placeholder: t("subscription_form_number_of_seats_placeholder") }) }), _jsx(FormField, { label: t("subscription_form_sign_in_method_label"), description: t("subscription_form_sign_in_method_description"), errorText: props.errors.signInMethod, children: _jsx(RadioGroup, { name: "signInMethod", onChange: (e) => props.onFormDataChange('signInMethod', e), value: props.formData.signInMethod, items: [
                            { value: "BUILDER_ID", label: `${t("subscription_form_builder_id_sign_in_label")}`, description: `${t("subscription_form_builder_id_sign_in_description")}` },
                            { value: "awsPartnerNetwork", label: `${t("subscription_form_partner_sign_in_label")}`, description: `${t("subscription_form_partner_sign_in_description")}` },
                        ] }) }), _jsx(FormField, { label: t("subscription_form_organization_idp_label"), description: t("subscription_form_organization_idp_description"), info: 'optional', errorText: props.errors.organizationIdentityProvider, children: _jsx(Input, { value: props.formData.organizationIdentityProvider, onChange: (e) => props.onFormDataChange('organizationIdentityProvider', e), name: "organizationIdentityProvider", placeholder: t("subscription_form_organization_idp_label") }) }), _jsx(FormField, { label: t("subscription_form_preferred_start_date_label"), info: "optional", description: t("subscription_form_preferred_start_date_description"), errorText: props.errors.preferredStartDate, children: _jsx(DatePicker, { onChange: (e) => props.onFormDataChange('preferredStartDate', e), value: props.formData.preferredStartDate, name: "preferredStartDate", placeholder: "YYYY/MM/DD" }) }), _jsx(SpaceBetween, { size: 's', children: props.formData.learningAdministrators.map((learingAdmin, index) => {
                        return (_jsx(Fragment, { children: _jsx(FormField, { label: index === 0 && t("subscription_form_learning_admins_label"), errorText: props.errors.learningAdministrators && props.errors.learningAdministrators[index], description: index === 0 && t("subscription_form_learning_admins_description"), stretch: true, children: _jsxs(Grid, { gridDefinition: [{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }], children: [_jsx(Input, { value: learingAdmin.laFirstName, onChange: (e) => { props.onFormDataChange('laFirstName', e, index); }, name: "laFirstName", placeholder: t("subscription_form_first_name_label") }), _jsx(Input, { value: learingAdmin.laLastName, onChange: (e) => { props.onFormDataChange('laLastName', e, index); }, name: "laLastName", placeholder: t("subscription_form_last_name_label") }), _jsx(Input, { value: learingAdmin.laEmail, onChange: (e) => { props.onFormDataChange('laEmail', e, index); }, name: "laEmail", placeholder: t("subscription_form_email_label") })] }) }) }, index));
                    }) }), _jsx(Button, { onClick: props.onAddLearningAdmin, children: t("subscription_form_add_another_admin_button_text") })] }) }));
};
