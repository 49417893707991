import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { SubscriptionReqTable } from "../../components/SubscriptionReqTable/SubscriptionReqTable";
import { SubscriptionSetup } from "../../components/SubscriptionSetup/SubscriptionSetup";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import Flashbar from "@amzn/awsui-components-react/polaris/flashbar";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT } from "../../common/routes";
import { NotificationContext, UserContext } from "../../App";
export const SubscriptionManagementPage = () => {
    const { t } = useTranslation();
    const { notification, setNotification } = useContext(NotificationContext);
    if (!setNotification) {
        throw new Error('setNotification is not defined');
    }
    const user = useContext(UserContext);
    return (_jsx(_Fragment, { children: _jsxs(SpaceBetween, { size: "m", "data-testid": 'subscription-management-page', children: [_jsx(Box, { variant: "h1", children: t("subscription_management_page_header") }), _jsx(SubscriptionSetup, {}), _jsx(SubscriptionReqTable, {})] }) }));
};
export const SubscriptionManagementPageLayout = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const { notification, setNotification } = useContext(NotificationContext);
    useEffect(() => {
        if (state?.notification) {
            setItems([
                {
                    type: 'success',
                    dismissible: true,
                    content: state?.notification,
                    onDismiss: () => {
                        setItems([]);
                        navigate(ROUTE_SKILLBUILDER_SUBSCRIPTION_MANAGEMENT, {});
                    },
                }
            ]);
        }
    }, []);
    return (_jsx(AppLayout, { content: _jsx(SubscriptionManagementPage, {}), toolsHide: true, navigationHide: true, notifications: _jsx(Flashbar, { items: items }) }));
};
