import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Container from "@amzn/awsui-components-react/polaris/container";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Link from "@amzn/awsui-components-react/polaris/link";
import { generateAwsSignInUrl } from "../../auth/awsSignIn";
export const SubscriptionPaymentDetailsContainer = (props) => {
    const { t } = useTranslation();
    if (!props.awsAccountId) {
        return _jsx(Container, { children: t("subscription_form_loading_aws_account_text") });
    }
    return (_jsx(Container, { header: _jsx(Box, { variant: "h2", children: t("subscription_form_payment_details_header") }), children: _jsxs(SpaceBetween, { size: "xxs", children: [_jsx(Box, { variant: "p", children: t("subscription_form_payment_details_description") }), _jsxs(SpaceBetween, { direction: "horizontal", size: "m", children: [_jsxs(Box, { variant: "strong", children: [t("subscription_form_payment_details_account_id_label"), " ", props.awsAccountId] }), _jsx(StatusIndicator, { type: "success" })] }), _jsx(Link, { href: generateAwsSignInUrl({ userId: "XXXX" }), children: t("subscription_form_payment_details_change_account_button_text") })] }) }));
};
