// TODO - This Enum should be imported from PlatformCommon package vs. delcared again here.
export var RoleType;
(function (RoleType) {
    RoleType["SERVICE"] = "SERVICE";
    RoleType["SUPER_USER"] = "SUPER_USER";
    RoleType["INTERNAL_EMPLOYEE"] = "INTERNAL_EMPLOYEE";
    RoleType["LEARNING_ADMIN"] = "LEARNING_ADMIN";
    RoleType["POST_AUTH_PRE_VIAS"] = "POST_AUTH_PRE_VIAS";
    RoleType["LEARNING_ACCOUNT_IT_CONTACT"] = "LEARNING_ACCOUNT_IT_CONTACT";
    RoleType["LEARNER"] = "LEARNER";
    RoleType["ILT_INSTRUCTOR"] = "ILT_INSTRUCTOR";
    RoleType["TRAINING_OPERATIONS"] = "TRAINING_OPERATIONS";
})(RoleType || (RoleType = {}));
export var UserStatus;
(function (UserStatus) {
    UserStatus["ACTIVE"] = "ACTIVE";
    UserStatus["PENDING"] = "PENDING";
    UserStatus["DEACTIVATED"] = "DEACTIVATED";
})(UserStatus || (UserStatus = {}));
