import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Input from "@amzn/awsui-components-react/polaris/input";
import Link from "@amzn/awsui-components-react/polaris/link";
import { useGetSpmsIdValidation } from "../../user/api/queries";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { useTranslation } from "react-i18next";
export const SpmsIdModal = ({ spmsModalVisible, setSpmsModalVisible, setAwsAccountModalVisible, }) => {
    const [spmsIdError, setSpmsIdError] = useState(false);
    const [modalSpmsId, setModalSpmsId] = useState('1837234');
    const { t } = useTranslation();
    const onSpmsValidationSuccess = (isValid) => {
        if (isValid) {
            sessionStorage.setItem('SpmsId', modalSpmsId);
            // Make graphql call to confirm SPMS ID is valid
            // If valid SPMS ID, then close SPMS modal and open AWS Sign In modal
            setSpmsModalVisible(false);
            setAwsAccountModalVisible(true);
        }
        else {
            // do error action
            console.log(`SPMS ID ${modalSpmsId} is not valid`);
        }
    };
    const [validateSpmsId, { called: spmsIdValidationCalled, loading: spmsIdValidationLoading, data: spmsIdValidationData }] = useGetSpmsIdValidation(+modalSpmsId, onSpmsValidationSuccess);
    const handleModalSpmsIdChange = (modalSpmsId) => {
        setModalSpmsId(modalSpmsId);
        if (modalSpmsId) {
            setSpmsIdError(false);
        }
        else {
            setSpmsIdError(true);
        }
    };
    return (_jsx(Modal, { className: "awsui-visual-refresh", onDismiss: () => setSpmsModalVisible(false), visible: spmsModalVisible, footer: _jsx(Box, { float: "right", children: _jsxs(SpaceBetween, { direction: "horizontal", size: "xs", children: [_jsx(Button, { variant: "link", onClick: () => setSpmsModalVisible(false), children: "Cancel" }), _jsx(Button, { disabled: spmsIdError || spmsIdValidationLoading, variant: "primary", iconAlign: "right", onClick: () => {
                            if (modalSpmsId) {
                                validateSpmsId();
                            }
                            else {
                                handleModalSpmsIdChange(modalSpmsId);
                            }
                        }, children: spmsIdValidationLoading ? _jsx(Spinner, {}) : 'Continue' })] }) }), header: t("subscription_request_spms_modal_header"), children: _jsxs(SpaceBetween, { size: "xs", children: [_jsxs(SpaceBetween, { size: "xs", direction: "horizontal", children: [_jsx(Box, { children: t("subscription_request_spms_modal_description") }), _jsx(Link, { href: "https://docs.aws.amazon.com/partner-central/latest/crm/crm-integration-faq.html", variant: "primary", external: true, externalIconAriaLabel: "(opens in new tab)", children: t("subscription_request_spms_modal_description_link") })] }), _jsx(Input, { onChange: ({ detail }) => handleModalSpmsIdChange(detail.value), value: modalSpmsId || '', placeholder: "SPMS ID", invalid: spmsIdError })] }) }));
};
