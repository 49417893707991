import axios from "axios";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import base64url from "base64url";
import { APPLICATION_URL } from "../common/constants";
import { NodeEnvironment, getNodeEnvironment } from "../common/nodeEnvironment";
import { ROUTE_HANDLE_AWS_SIGN_IN } from "../common/routes";
export function getBaseAwsSignInUrl() {
    const env = getNodeEnvironment();
    switch (env) {
        case NodeEnvironment.DEVELOPMENT:
            return "https://r1gcyvnq3m.execute-api.us-east-1.amazonaws.com/dev";
        default:
            return "https://383oxd1ye4.execute-api.us-east-1.amazonaws.com/prod";
    }
}
const PKCE_KEY = "pkce_key";
const AWS_SIGN_IN_AUTHORIZE_ENDPOINT = `${getBaseAwsSignInUrl()}/authorize`;
const AWS_SIGN_IN_TOKEN_ENDPOINT = `${getBaseAwsSignInUrl()}/token`;
export const AWS_SIGN_IN_LITERAL = "aws_sign_in";
export const SEARCH_PARAM_CODE = "code";
export const SEARCH_PARAM_STATE = "state";
const ROOT_ARN_REGEX = /^arn:aws:iam::\d{12}:root$/;
export const isUserRoot = (payload) => {
    const isValidRootArn = ROOT_ARN_REGEX.test(payload.arn) &&
        `arn:aws:iam::${payload.name}:root` === payload.arn;
    const isValidPrincipal = payload.name && payload.principalId === payload.name;
    return ((isValidRootArn && isValidPrincipal) ||
        getNodeEnvironment() === NodeEnvironment.GAMMA ||
        getNodeEnvironment() === NodeEnvironment.BETA ||
        getNodeEnvironment() === NodeEnvironment.DEVELOPMENT);
};
/**
 * Signin to AWS flow:
 *
 * In generateAwsSignInUrl, a code_verifier is created (cryptographic random string), stored, and used to create codeChallenge
 * (a sha256 hash of code_verifier which will be used by the IDP server to verify the token call later). An 'authorization request'
 * is made to the IDP server which passes codeChallenge, redirectURI (/aws-signin), and state (any info we need upon redirect
 * to the checkout app).
 *
 * After the user signs in to their AWS account the client is redirected back to the checkout app on the /aws-signin with the URL parameters 'code' and 'state'.
 * code is an authorization code which is used for verification in the token call later (https://datatracker.ietf.org/doc/html/rfc7636#section-4.4).
 * state is the same value we passed in previous authorization call.
 *
 * Then, a request is made to get the account JWT (fetchAwsAccountJwt). The request passes the code_verifier, grant_type, client_id, and code. The IDP
 * server verifies the request by (1) hashing (sha256) code_verifier and comparing the result against codeChallenge from the previous authorization
 * request and (2) comparing the code against the code that was returned from the previous authorization request. After verification, the IDP
 * server returns an id token (jwt - https://openid.net/specs/openid-connect-core-1_0.html#IDToken) which provides the user's aws account id, arn, etc.
 *
 * Notes:
 *
 * 1. 'redirectURI' must be allow-listed by the IDP (e.g. https://code.amazon.com/reviews/CR-90100391)
 * 2. 'state' (sent in the authorization call, returned from token call) should be used to preserve any values needed on redirect to the checkout session. Since
 *   we can't pass back data as URL path or search params (due to Note #1), we must use this to preserve things like the checkout session ID
 */
function generateCodeVerifier() {
    const buffer = new Uint8Array(128);
    const codeVerifier = window.crypto.getRandomValues(buffer).toString();
    window.sessionStorage.setItem(PKCE_KEY, codeVerifier);
    return codeVerifier;
}
function getAndClearCodeVerifier() {
    const codeVerifier = window.sessionStorage.getItem(PKCE_KEY);
    window.localStorage.removeItem(PKCE_KEY);
    return codeVerifier;
}
export function generateAwsSignInUrl(applicationState) {
    const codeVerifier = generateCodeVerifier();
    const state = {
        ...applicationState,
        // Mark this as an aws sign in type
        signInType: AWS_SIGN_IN_LITERAL,
    };
    const codeChallenge = base64url.fromBase64(sha256(codeVerifier).toString(Base64));
    const redirectURI = `${APPLICATION_URL[getNodeEnvironment().toLocaleUpperCase()]}${ROUTE_HANDLE_AWS_SIGN_IN}`;
    return axios.getUri({
        url: AWS_SIGN_IN_AUTHORIZE_ENDPOINT,
        params: {
            state: btoa(JSON.stringify({
                codeChallenge,
                redirectURI,
                state: encodeURI(JSON.stringify(state)),
            })),
        },
    });
}
export async function fetchAwsAccountJwt(code) {
    const requestParams = {
        grant_type: "authorization_code",
        client_id: "sbts-frontend",
        code_verifier: getAndClearCodeVerifier(),
        code,
    };
    const body = Object.entries(requestParams)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join("&");
    const response = await axios({
        method: "POST",
        url: AWS_SIGN_IN_TOKEN_ENDPOINT,
        data: body,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
        },
    });
    return response.data.id_token;
}
export async function fetchAwsAccountType(awsAccountId) {
    // const response = await axios.get('#', { params: { awsAccountId } })
    // return response?.data || 'partner';
    return Promise.resolve('partner');
}
